import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import Desktop1 from "./components/Desktop";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "Hazirlan AI"; // Default title
    let metaDescription = "Türkiye’nin İlk Yapay Zekalı Destekli Matematik Soru Bankası"; // Default description

    switch (pathname) {
      case "/":
        title = "Hazirlan AI"; // Adjust this title as needed
        metaDescription = "Türkiye’nin İlk Yapay Zekalı Destekli Matematik Soru Bankası";
        break;
      // Add more cases if you have other routes
      default:
        break;
    }

    if (title) {
      document.title = title;
    }

    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute("content", metaDescription);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = metaDescription;
      document.head.appendChild(meta);
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Desktop1 />} />

      {/* Catch-all route to redirect any undefined route to / */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
